import { Box, Grid, Typography } from "@mui/material";
import logo from "../assets/logo/radix.png";

import facebookIcon from "../assets/icon/social-media/facebook.png"
import twitterIcon from "../assets/icon/social-media/twitter.png"
import youtubeIcon from "../assets/icon/social-media/youtube .png"
import instagramIcon from "../assets/icon/social-media/instagram.png"
import linkedinIcon from "../assets/icon/social-media/linkedin.png"
import { useEffect, useState } from "react";
import useWidth from "../hooks/useWidth";


const translations = {
    english: {
        year: "© 2024",
        company: "Radix Group Incorporated.",
        allRightsReserved: "All Rights Reserved.",
        privacy: "Privacy",
        termsOfUse: "Terms of Use",
        dataProtection: "Data Protection",
        cookies: "Cookies",
        socialMedia: "Social Media"
    },
    korean: {
        year: "© 2024",
        company: "Radix 그룹 주식회사.",
        allRightsReserved: "모든 권리 보유.",
        privacy: "개인정보 보호",
        termsOfUse: "이용 약관",
        dataProtection: "데이터 보호",
        cookies: "쿠키",
        socialMedia: "소셜 미디어"
    },
    spanish: {
        year: "© 2024",
        company: "Radix Group Incorporated.",
        allRightsReserved: "Todos los derechos reservados.",
        privacy: "Privacidad",
        termsOfUse: "Términos de uso",
        dataProtection: "Protección de datos",
        cookies: "Cookies",
        socialMedia: "Redes sociales"
    }
};



const FooterComponent = () => {

    const breakpoints = useWidth();

    const [language, setLanguage] = useState("english");

    useEffect(() => {
        // Retrieve the selected language from local storage
        const storedLanguage = localStorage.getItem('language');
        
        // Set the language from local storage if it exists
        if (storedLanguage) {
          setLanguage(storedLanguage);
        }
      }, []);

    return(
            <Box class="container">
                <Grid container sx={{padding: 0}}>
                    <Grid item xs={12} sm={7} sx={{padding: 0}}>
                        <Grid container sx={{padding: 0}}>
                            <Grid item xs={12} sm={3.5} sx={{textAlign: 'start'}}>
                                <img
                                    alt=""
                                    src={logo}
                                    style={{
                                        height: breakpoints === 'xs' ? 50 : 60,
                                        verticalAlign: "middle",
                                        cursor: "pointer",
                                        imageRendering: "pixelated",
                                        imageRendering: "-webkit-optimize-contrast",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{textAlign: 'start', mt: breakpoints === 'xs' ? 3 : 0}}>
                                <Typography>{translations[language].year}</Typography>
                                <Typography sx={{mt:1}}>{translations[language].company}</Typography>
                                <Typography sx={{mt:1}}>{translations[language].allRightsReserved}</Typography>
                            </Grid>
                   
                            <Grid item xs={12} sm={4} sx={{textAlign: 'start', mt: breakpoints === 'xs' ? 3 : 0}}>
                                <Typography onClick={() => window.location.href = "/contact/disclaimer/privacy"} sx={{cursor: 'pointer'}} >{translations[language].privacy}</Typography>
                                <Typography onClick={() => window.location.href = "/contact/disclaimer"} sx={{mt:1, cursor: 'pointer'}}>{translations[language].termsOfUse}</Typography>
                                <Typography onClick={() => window.location.href = "/contact/radix-group-incorporated-privacy-notice"} sx={{mt:1, cursor: 'pointer'}}>{translations[language].dataProtection}</Typography>
                                <Typography onClick={() => window.location.href = "/contact/cookies"} sx={{mt:1, cursor: 'pointer'}}>{translations[language].cookies}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} sx={{textAlign: 'start', mt: breakpoints === 'xs' ? 3 : 0}}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography>{translations[language].socialMedia}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{mt: 2}} >
                                <Box sx={{display: 'flex'}}>
                                    <a  href="https://www.facebook.com" target="_blank" ><img src={facebookIcon} style={{width: breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}}  /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://twitter.com" target="_blank" ><img src={twitterIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://www.youtube.com" target="_blank" ><img src={youtubeIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://www.instagram.com" target="_blank" ><img src={instagramIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://www.linkedin.com" target="_blank" ><img src={linkedinIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box sx={{mt:2}} />

            </Box>
    )
}

export default FooterComponent