import { Box, Button, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


const WebAppbarComponent = (props) => {

  const {
    text,
    handleWhoWeAreMenuOpen,
    selectedWhoWeAre,
    handleWhatWeDoMenuOpen,
    selectedWhatWeDo,
    selectedNews,
    selectedCareer,
    selectedInvestor,
    selectedSupplier,
  } = props;


  // Function to detect zoom level
function detectZoom() {
  var ratio = 0,
      screen = window.screen,
      ua = navigator.userAgent.toLowerCase();

  if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
  }
  else if (~ua.indexOf('msie')) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
      }
  }
  else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
      ratio = window.outerWidth / window.innerWidth;
  }

  if (ratio) {
      return Math.round(ratio * 100);
  }

  return 100; // Default to 100% zoom if unable to detect
}

// Function to apply styles based on zoom level
function applyStylesForZoomLevel() {
  var zoomLevel = detectZoom();
  if (zoomLevel === 125) {
      // Apply styles for 125% zoom
      document.body.classList.add('zoom-125');

  }  else if(zoomLevel === 150) {
      document.body.classList.add('zoom-150');

  }else {
      // Remove styles if zoom level is not 125%
      document.body.classList.remove('zoom-125');
      document.body.classList.remove('zoom-150');
  }
}

// Apply styles initially and listen for resize events to reapply if needed
applyStylesForZoomLevel();
window.addEventListener('resize', applyStylesForZoomLevel);



  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        endIcon={<ExpandMoreIcon />}
        onClick={handleWhoWeAreMenuOpen}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          ...(selectedWhoWeAre === "who-we-are" ||
            selectedWhoWeAre === "leadership-governance" ||
            selectedWhoWeAre === "our-business" ||
            selectedWhoWeAre === "social-impact" ||
            selectedWhoWeAre === "sustainability" ||
            selectedWhoWeAre === "ethics" ||
            selectedWhoWeAre === "economic-and-workforce-impact" ? { color: '#017eff' } : {})
        }}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.whoWeAre}
        </Typography>
      </Button>


      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        endIcon={<ExpandMoreIcon />}
        onClick={handleWhatWeDoMenuOpen}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          ...(selectedWhatWeDo === "ahead-of-ready" ||
            selectedWhatWeDo === "domain-operations" ||
            selectedWhatWeDo === "autonomy-ai" ||
            selectedWhatWeDo === "cyber" ||
            selectedWhatWeDo === "deference-capability" ||
            selectedWhatWeDo === "transformative-technology" ? { color: '#017eff' } : {})
        }}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.whatWeDo}
        </Typography>
      </Button>


      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        onClick={() => window.location.href = "/news"}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          ...(selectedNews === "news" ? { color: '#017eff' } : {})
        }}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.news}
        </Typography>
      </Button>


      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        onClick={() => window.location.href = "/career"}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          ...(selectedCareer === "career" ? { color: '#017eff' } : {})
        }}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.careers}
        </Typography>
      </Button>


      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        onClick={() => window.location.href = "/investors"}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          ...(selectedInvestor === "investors" ? { color: '#017eff' } : {})
        }}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.investors}
        </Typography>
      </Button>


      <Button
        id="demo-customized-button"
        variant="text"
        disableElevation
        onClick={() => window.location.href = "/suppliers"}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          ...(selectedSupplier === "suppliers" ? { color: '#017eff' } : {})
        }}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px', }}>
          {text.suppliers}
        </Typography>
      </Button>

    </Box>

  )
}

export default WebAppbarComponent