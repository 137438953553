import { Grid, Typography } from "@mui/material"
import {Link} from 'react-router-dom';
import '../../LinkComponent.css'


const translations = {
    english: {
        whatWeDo: "What We Do",
        ourCapabilities: "Our Capabilities",
        ourProducts: "Our Products",
        whoWeAre: "Who We Are",
        community: "Community",
        leadership: "Leadership",
        newsroom: "Newsroom",
        sustainability: "Sustainability",
        aboutUs: "About Us",
        information: "Information",
        employees: "Employees",
        international: "International",
        investors: "Investors",
        suppliers: "Suppliers",
        connect: "Connect",
        contactUs: "Contact Us",
        mediaRelations: "Media Relations",
        multimedia: "Multimedia",
        disclosures: "Disclosures"
    },
    korean: {
        whatWeDo: "우리가 하는 일",
        ourCapabilities: "우리의 능력",
        ourProducts: "우리의 제품",
        whoWeAre: "우리는 누구인가",
        community: "커뮤니티",
        leadership: "리더십",
        newsroom: "뉴스룸",
        sustainability: "지속가능성",
        aboutUs: "회사 소개",
        information: "정보",
        employees: "직원",
        international: "국제",
        investors: "투자자",
        suppliers: "공급업체",
        connect: "연결",
        contactUs: "연락처",
        mediaRelations: "미디어 관계",
        multimedia: "멀티미디어",
        disclosures: "공개"
    },
    spanish: {
        whatWeDo: "Lo Que Hacemos",
        ourCapabilities: "Nuestras Capacidades",
        ourProducts: "Nuestros Productos",
        whoWeAre: "Quiénes Somos",
        community: "Comunidad",
        leadership: "Liderazgo",
        newsroom: "Sala de Prensa",
        sustainability: "Sostenibilidad",
        aboutUs: "Sobre Nosotros",
        information: "Información",
        employees: "Empleados",
        international: "Internacional",
        investors: "Inversionistas",
        suppliers: "Proveedores",
        connect: "Conectar",
        contactUs: "Contáctenos",
        mediaRelations: "Relaciones con los Medios",
        multimedia: "Multimedia",
        disclosures: "Divulgaciones"
    },
};

const LinksComponent = (props) => {

    const { language } = props;


    console.log("translationsxxxxx", translations)
    console.log("languagexxx", language)

    return (
      <Grid container spacing={{ xs: 2, sm: 3, md: 0.5 }}>
        <Grid item xs={12} sm={6} md={4}>
          <div className="col-6 col-lg-2 pl-md-5 mb-5 mb-lg-0">
            <Typography
              className="listedLink reverseWhite"
              sx={{ fontSize: 18, textDecoration: 'none', color: 'white' }}
            >
              {translations[language].whatWeDo}
            </Typography>
            <Link to="/our-capabilities">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].ourCapabilities}
              </Typography>
            </Link>
            <Link to="/our-products">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].ourProducts}
              </Typography>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="col-6 col-lg-2 pl-md-5 mb-5 mb-lg-0">
            <Typography
              className="listedLink reverseWhite"
              sx={{ fontSize: 18, textDecoration: 'none', color: 'white' }}
            >
              {translations[language].whoWeAre}
            </Typography>
            <Link to="/community">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].community}
              </Typography>
            </Link>
            <Link to="/leadership">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].leadership}
              </Typography>
            </Link>
            <Link to="/newsroom">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].newsroom}
              </Typography>
            </Link>
            <Link to="/who-we-are/sustainability">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].sustainability}
              </Typography>
            </Link>
            <Link to="/who-we-are">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].aboutUs}
              </Typography>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="col-6 col-lg-2 pl-md-5 mb-5 mb-lg-0">
            <Typography
              className="listedLink reverseWhite"
              sx={{ fontSize: 18, textDecoration: 'none', color: 'white' }}
            >
              {translations[language].information}
            </Typography>
            <Link to="/employees">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].employees}
              </Typography>
            </Link>
            <Link to="/international">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].international}
              </Typography>
            </Link>
            <Link to="/investors">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].investors}
              </Typography>
            </Link>
            <Link to="/suppliers">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].suppliers}
              </Typography>
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="col-6 col-lg-2 pl-md-5 mb-5 mb-lg-0">
            <Typography
              className="listedLink reverseWhite"
              sx={{ fontSize: 18, textDecoration: 'none', color: 'white' }}
            >
              {translations[language].connect}
            </Typography>
            <Link to="/contact-us">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].contactUs}
              </Typography>
            </Link>
            <Link to="/medial-relations">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].mediaRelations}
              </Typography>
            </Link>
            <Link to="/multimedia">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].multimedia}
              </Typography>
            </Link>
            <Link to="/disclosures">
              <Typography
                className="listedLink"
                sx={{ fontSize: 16, textDecoration: 'none', color: 'white' }}
              >
                {translations[language].disclosures}
              </Typography>
            </Link>
          </div>
        </Grid>
    </Grid>
    )
}

export default LinksComponent
