import React from 'react';
import { ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import nextixPeople from '../../assets/image/nextix-image.jpg';

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

const MeetOurPeopleComponent = () => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

    const itemData = [
        {
            img: nextixPeople,
            title: 'Breakfast',
            author: '@bkristastucchio',
            featured: true,
        },
        {
            img: nextixPeople,
            title: 'Burger',
            author: '@rollelflex_graphy726',
        },
        {
            img: nextixPeople,
            title: 'Camera',
            author: '@helloimnik',
        },
    ];

    return (
        <ImageList
            sx={{
                width: '100%',
                height: 'auto',
                transform: 'translateZ(0)',
                position: 'inherit'
            }}
            gap={1}
            cols={isSmallScreen ? 1 : 2} 
        >
            {itemData.map((item) => {
                const cols = item.featured ? (isSmallScreen ? 1 : 2) : 1;
                const rows = item.featured ? (isSmallScreen ? 2 : 1) : 1;

                return (
                    <ImageListItem key={item.img} cols={cols} rows={rows} onClick={() => navigate('/meet-our-people/career/life-at' + item?.title)} sx={{cursor: 'pointer'}} >
                        <img
                            {...srcset(item.img, 250, 200, rows, cols)}
                            alt={item.title}
                            loading="lazy"
                            style={{ height: item.featured ? (isSmallScreen ? 250 : 350) : 250 }}
                        />
                        <ImageListItemBar
                            sx={{
                                background: 'linear-gradient(to bottom, rgb(0 0 0 / 21%) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            title={item.title}
                            position="bottom"
                            actionPosition="left"
                        />
                    </ImageListItem>
                );
            })}
        </ImageList>
    );
};

export default MeetOurPeopleComponent;
