import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import { Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ReelsComponent = () => {
    const videoIds = [
        "UklwTkNkr8c",
        "K3Ku4T2wn7M",
        "s6K216d3apg",
        "glDgtUXhS3c"
    ];

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia('(max-width: 768px)').matches);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Grid container spacing={3} sx={{ mt: 1 }}>
            {isMobile ? (
                <Swiper
                    modules={[Navigation, Scrollbar]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                >
                    {videoIds.map((videoId, index) => (
                        <SwiperSlide key={index}>
                            <div style={{ position: "relative", width: "100%", height: "600px" }}>
                                <iframe
                                    width="100%"
                                    height="600"
                                    src={`https://www.youtube.com/embed/${videoId}?controls=0`}
                                    title={`YouTube Reel ${index + 1}`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                videoIds.map((videoId, index) => (
                    <Grid key={index} item xs={12} sm={3} md={3}>
                        <div style={{ position: "relative", width: "100%", height: "600px" }}>
                            <iframe
                                width="100%"
                                height="600"
                                src={`https://www.youtube.com/embed/${videoId}?controls=0`}
                                title={`YouTube Reel ${index + 1}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default ReelsComponent;
