import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../assets/logo/radix.png";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import AppsIcon from "@mui/icons-material/Apps";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useWidth from '../hooks/useWidth';
import MobileAppbarComponent from "./mobile-appbar.component";
import WebAppbarComponent from "./web-appbar.component";
import SubMenuComponent from "./sub-menu.component";


const languageTexts = {
  english: {
    whoWeAre: "Who We Are",
    whatWeDo: "What We Do",
    news: "News",
    careers: "Careers",
    investors: "Investors",
    suppliers: "Suppliers",
    aboutUs: "About Us",
    leadership: "Leadership and Governance",
    ourBusiness: "Our Business",
    socialImpact: "Social Impact",
    sustainability: "Sustainability",
    ethics: "Ethics",
    economicImpact: "Economic and Workforce Impact",
    products: "Products",
    allDomainOperations: "All Domain Operations",
    autonomyAI: "Autonomy and AI",
    cyber: "Cyber",
    deferenceCapabilities: "Deference Capabilities",
    transformativeTech: "Transformative Technology",
    language: "Language",
    search: "Search",
  },
  // korean: {
  //   whoWeAre: "우리는 누구인가",
  //   whatWeDo: "우리가 하는 일",
  //   news: "뉴스",
  //   careers: "경력",
  //   investors: "투자자",
  //   suppliers: "공급 업체",
  //   aboutUs: "회사 소개",
  //   leadership: "지도부 및 거버넌스",
  //   ourBusiness: "우리의 사업",
  //   socialImpact: "사회적 영향",
  //   sustainability: "지속 가능성",
  //   ethics: "윤리",
  //   economicImpact: "경제 및 노동력 영향",
  //   products: "제품",
  //   allDomainOperations: "모든 도메인 운영",
  //   autonomyAI: "자율성과 인공 지능",
  //   cyber: "사이버",
  //   deferenceCapabilities: "변화의 능력",
  //   transformativeTech: "혁신 기술",
  //   language: "언어",
  //   search: "검색",
  // },
  // spanish: {
  //   whoWeAre: "Quiénes somos",
  //   whatWeDo: "Lo que hacemos",
  //   news: "Noticias",
  //   careers: "Carreras",
  //   investors: "Inversores",
  //   suppliers: "Proveedores",
  //   aboutUs: "Acerca de nosotros",
  //   leadership: "Liderazgo y gobernabilidad",
  //   ourBusiness: "Nuestro negocio",
  //   socialImpact: "Impacto social",
  //   sustainability: "Sostenibilidad",
  //   ethics: "Ética",
  //   economicImpact: "Impacto económico y laboral",
  //   products: "Productos",
  //   allDomainOperations: "Todas las operaciones de dominio",
  //   autonomyAI: "Autonomía e IA",
  //   cyber: "Ciber",
  //   deferenceCapabilities: "Capacidades de deferencia",
  //   transformativeTech: "Tecnología transformadora",
  //   language: "Idioma",
  //   search: "Buscar",
  // },
};



function AppbarComponent() {
  const url = window.location.pathname;
  const navigate = useNavigate();
  const breakpoints = useWidth();

  const [value, setValue] = useState(0);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [whoeWeAreMenuOpen, setWhoWeAreMenuOpen] = useState(false);
  const [whoeWeAreMenuAnchorEl, setWhoWeAreMenuAnchorEl] = useState(null);
  const [whatWeDoMenuOpen, setWhatWeDoMenuOpen] = useState(false);
  const [whatWeDoMenuAnchorEl, setWhatWeDoMenuAnchorEl] = useState(null);

  const [selectedWhoWeAre, setSelectedWhoWeAre] = useState(null);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [selectedWhatWeDo, setSelectedWhatWeDo] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);

  const [language, setLanguage] = useState("english");
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);

  const [showSearch, setShowSearch] = useState(false);

  const handleLanguageMenuOpen = (event) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenuAnchorEl(null);
  };

  const handleButtonClick = () => {
    setShowSearch(!showSearch);
  };

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
    handleLanguageMenuClose();
    window.location.reload();
  };

  const languageMenuId = 'language-menu';

  const storedLanguage = localStorage.getItem('language');

  // Rendered text based on selected language
  const text = languageTexts['english'];



  const handleWhoWeAreMenuOpen = (event) => {
    setWhoWeAreMenuAnchorEl(event.currentTarget);
    setWhoWeAreMenuOpen(true);
  };

  const handleWhoWeAreMenuClose = () => {
    setWhoWeAreMenuAnchorEl(null);
    setWhoWeAreMenuOpen(false);
  };

  const handleWhatWeDoMenuOpen = (event) => {
    setWhatWeDoMenuAnchorEl(event.currentTarget);
    setWhatWeDoMenuOpen(true);
  };

  const handleWhatWeDoMenuClose = () => {
    setWhatWeDoMenuAnchorEl(null);
    setWhatWeDoMenuOpen(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  
  useEffect(() => {
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];
    console.log("lastSegmentxxx", lastSegment)

    if (lastSegment === "who-we-are") {
      setSelectedWhoWeAre("who-we-are");
    } else if (lastSegment === "leadership-governance") {
      setSelectedWhoWeAre("leadership-governance");
    } else if (lastSegment === "our-business") {
      setSelectedWhoWeAre("our-business");
    } else if (lastSegment === "social-impact") {
      setSelectedWhoWeAre("social-impact");
    } else if (lastSegment === "sustainability") {
      setSelectedWhoWeAre("sustainability");
    } else if (lastSegment === "ethics") {
      setSelectedWhoWeAre("ethics");
    } else if (lastSegment === "economic-and-workforce-impact") {
      setSelectedWhoWeAre("economic-and-workforce-impact");
    } else if (lastSegment === "career") {
      setSelectedCareer("career");
    } else if (lastSegment === "investors") {
      setSelectedInvestor("investors")
    } else if (lastSegment === "suppliers") {
      setSelectedSupplier("suppliers")
    } else if (lastSegment === "investors") {
      setSelectedInvestor("investors")
    } else if (lastSegment === "domain-operations") {
      setSelectedWhatWeDo("domain-operations")
    } else if (lastSegment === "autonomy-ai") {
      setSelectedWhatWeDo("autonomy-ai")
    } else if (lastSegment === "cyber") {
      setSelectedWhatWeDo("cyber")
    } else if (lastSegment === "deference-capability") {
      setSelectedWhatWeDo("deference-capability")
    } else if (lastSegment === "transformative-technology") {
      setSelectedWhatWeDo("transformative-technology")
    } else if (lastSegment === "ahead-of-ready") {
      setSelectedWhatWeDo("ahead-of-ready")
    } else if (lastSegment === "news") {
      setSelectedNews("news")
    } else {
      setSelectedWhoWeAre(null);
      setSelectedInvestor(null);
      setSelectedCareer(null);
      setSelectedSupplier(null);
      setSelectedWhatWeDo(null);
      setSelectedNews(null);
    }

  }, [url]);


  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <AppBar
      position="static"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        top: 0,
        left: "auto",
        right: 0,
        color: "rgb(255, 255, 255)",
        backgroundColor: "#fff",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        width: "98%",
        boxSizing: "border-box",
      }}
    >
      <div className="containerMain">
        <Toolbar sx={{ width: "100%", paddingLeft: '0px !important', paddingRight: '0px !important', paddingTop: 1 }} >
          <Button
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              mr: 2,
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              padding: '0px',
            }}
            disableRipple
          >
            <img
              alt=""
              src={logo}
              onClick={(e) => (window.location.href = "/")}
              style={{
                height: breakpoints === 'xs' || breakpoints === 'sm' ? 30 : 50,
                verticalAlign: "middle",
                cursor: "pointer",
                imageRendering: "pixelated",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Button>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <WebAppbarComponent
              text={text}
              handleWhoWeAreMenuOpen={handleWhoWeAreMenuOpen}
              selectedWhoWeAre={selectedWhoWeAre}
              handleWhatWeDoMenuOpen={handleWhatWeDoMenuOpen}
              selectedWhatWeDo={selectedWhatWeDo}
              selectedNews={selectedNews}
              selectedCareer={selectedCareer}
              selectedInvestor={selectedInvestor}
              selectedSupplier={selectedSupplier}
              handleLanguageMenuOpen={handleLanguageMenuOpen}
              handleButtonClick={handleButtonClick}
              showSearch={showSearch}
            />
          </Box>


          <Box sx={{ flexGrow: 1 }} />

              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color={"black"}
                sx={{display: { xs: "flex", md: "none" }}}
              >
                <AppsIcon />
              </IconButton>

            <Button sx={{ marginTop: '.8em', color: '#000', height: '30px', display: { xs: "none", md: "flex" } }} onClick={handleLanguageMenuOpen} >
              <LanguageOutlinedIcon />
            </Button>

            <Box sx={{ display: 'flex', alignItems: 'center', pt: breakpoints === 'xs' || breakpoints === 'sm' ? 0 : '20px' }}>
              {showSearch && (
                <TextField
                  hiddenLabel
                  id="search-input"
                  label="Search"
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: '1em', width: '150px' }}
                />
              )}
              <Button
                sx={{ color: '#000', height: '30px', pb: 1 }}
                onClick={handleButtonClick}
              >
                <SearchOutlinedIcon />
              </Button>
            </Box>
        </Toolbar>
      </div>


      {/* Sub-Menus */}
      <SubMenuComponent
        text={text}
        whoeWeAreMenuAnchorEl={whoeWeAreMenuAnchorEl}
        whoeWeAreMenuOpen={whoeWeAreMenuOpen}
        handleWhoWeAreMenuClose={handleWhoWeAreMenuClose}
        selectedWhoWeAre={selectedWhoWeAre}
        whatWeDoMenuAnchorEl={whatWeDoMenuAnchorEl}
        whatWeDoMenuOpen={whatWeDoMenuOpen}
        handleWhatWeDoMenuClose={handleWhatWeDoMenuClose}
        selectedWhatWeDo={selectedWhatWeDo}
        selectedNews={selectedNews}
        selectedCareer={selectedCareer}
        selectedInvestor={selectedInvestor}
        selectedSupplier={selectedSupplier}
        languageMenuAnchorEl={languageMenuAnchorEl}
        handleLanguageChange={handleLanguageChange}
        handleLanguageMenuClose={handleLanguageMenuClose}
        languageMenuId={languageMenuId}
      />


      <MobileAppbarComponent
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        handleMobileMenuClose={handleMobileMenuClose}
        handleWhoWeAreMenuOpen={handleWhoWeAreMenuOpen}
        selectedWhoWeAre={selectedWhoWeAre}
        handleWhatWeDoMenuOpen={handleWhatWeDoMenuOpen}
        selectedWhatWeDo={selectedWhatWeDo}
        handleLanguageMenuOpen={handleLanguageMenuOpen}
        mobileMenuId={mobileMenuId}
        selectedNews={selectedNews}
        selectedCareer={selectedCareer}
        selectedInvestor={selectedInvestor}
        selectedSupplier={selectedSupplier}
      />

    </AppBar>


  );
}

export default AppbarComponent;