import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../BannerScreenSwiper.css';
import GameChangerTabComponent from "../component/game-changers/game-changer-tab.component";

const GameChangerScreen = () => {
    const videoIds = [
        {id: "K3Ku4T2wn7M" , title: 'Episode 1: Test Episode' },
        {id: "s6K216d3apg" , title: 'Episode 2: Test Episode'},
        {id: "glDgtUXhS3c" , title: 'Episode 3: Test Episode' },
    ];

    return (
        <Box>
            <Box class="container-fluid container-xxxl">
                <Box class="bannerOverlayArea">
                    <Box class="bannerOverlayEmbed embed-responsive embed-responsive-16by9">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/stE6bSylt_Y" title="Siempre es bonito ver el nacimiento de algo." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Box>
                </Box>
                <Typography style={{ textAlign: 'start', fontSize: 24 }}>Game Changer: A Limited Series</Typography>
                <br />
                <Grid container sx={{ textAlign: 'start' }}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: { xs: 18, sm: 24, md: 30, lg: 38 }, mb: 5, mt: 5}}>
                            There are people who work for a company and there are people who inspire change. Game Changers, a limited series, highlights employees who are reimagining the way we work and support our customer’s missions.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: { xs: 18, sm: 24, md: 30, lg: 38 }, mt: 5 }}>
                            Meet Radix's Game Changers.
                        </Typography>
                    </Grid>
                </Grid>
                <br />
            </Box>
            <br />
            <Box sx={{ backgroundColor: '#f2f2f2' }}>
                <Box class="container-fluid container-xxxl">
                    <br/>
                    <Swiper
                        modules={[Navigation, Scrollbar]}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        spaceBetween={30}
                        slidesPerView={1}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                    >
                        {videoIds.map((videoId, index) => (
                            <SwiperSlide key={index}>
                                <Grid item xs={12} sm={4}>
                                    <Box style={{ position: "relative", width: "100%", height: "600px", }} >
                                        <iframe
                                            width="100%"
                                            height="600"
                                            src={`https://www.youtube.com/embed/${videoId?.id}?controls=0`}
                                            title={`YouTube Reel ${index + 1}`}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Box>
                                    <Typography sx={{mt: 3, fontSize: 18, pb: 2}}>{videoId?.title}</Typography>
                                </Grid>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <br/>
                </Box>
            </Box>
            <br/>
            <Box >
              <GameChangerTabComponent />
            </Box>
        </Box>
    );
}

export default GameChangerScreen;
