
const CapabilitiesByDomainOnClickScreen = () => {
   
    return (
        <p>Capabilities By Domain Image OnClick here</p>
    )
}

export default CapabilitiesByDomainOnClickScreen



