import { Box, Menu, MenuItem } from "@mui/material"

const SubMenuComponent = (props) => {

    const { 
        text,
        whoeWeAreMenuAnchorEl,
        whoeWeAreMenuOpen,
        handleWhoWeAreMenuClose,
        selectedWhoWeAre,
       whatWeDoMenuAnchorEl,
        whatWeDoMenuOpen,
       handleWhatWeDoMenuClose,
       selectedWhatWeDo,
       selectedNews,
       selectedCareer,
       selectedInvestor,
       selectedSupplier,
      languageMenuAnchorEl,
      handleLanguageChange,
         handleLanguageMenuClose,
         languageMenuId
    } = props;

    return(
        <Box>
            <Menu
        anchorEl={whoeWeAreMenuAnchorEl}
        open={whoeWeAreMenuOpen}
        onClose={handleWhoWeAreMenuClose}
      >
        <MenuItem
        onClick={() => [window.location.href = '/who-we-are', handleWhoWeAreMenuClose]}
        sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "who-we-are" ? { color: '#017eff' } : {}) }}
        >
          {text.aboutUs}
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/who-we-are/leadership-governance', handleWhoWeAreMenuClose]}
          sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "leadership-governance" ? { color: '#017eff' } : {}) }}
        >
          {text.leadership}
        </MenuItem>
        <MenuItem
         onClick={() => [window.location.href = '/who-we-are/our-business', handleWhoWeAreMenuClose]}
         sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "our-business" ? { color: '#017eff' } : {}) }}
        >
          {text.ourBusiness}
        </MenuItem>
        <MenuItem
         onClick={() => [window.location.href = '/who-we-are/social-impact', handleWhoWeAreMenuClose]}
         sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "social-impact" ? { color: '#017eff' } : {}) }}
        >
          {text.socialImpact}
        </MenuItem>
        <MenuItem
         onClick={() => [window.location.href = '/who-we-are/sustainability', handleWhoWeAreMenuClose]}
         sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "sustainability" ? { color: '#017eff' } : {}) }}
        >
          {text.sustainability}
        </MenuItem>
        <MenuItem
         onClick={() => [window.location.href = '/who-we-are/ethics', handleWhoWeAreMenuClose]}
         sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "ethics" ? { color: '#017eff' } : {}) }}
        >
          {text.ethics}
        </MenuItem>
        <MenuItem
         onClick={() => [window.location.href = '/who-we-are/economic-and-workforce-impact', handleWhoWeAreMenuClose]}
         sx={{ fontSize: '14px', color: '#000', ...(selectedWhoWeAre === "economic-and-workforce-impact" ? { color: '#017eff' } : {}) }}
        >
          {text.economicImpact}
        </MenuItem>
      </Menu>


      <Menu
        anchorEl={whatWeDoMenuAnchorEl}
        open={whatWeDoMenuOpen}
        onClose={handleWhatWeDoMenuClose}
      >
        <MenuItem
          onClick={() => [window.location.href = '/learn-more/ahead-of-ready',handleWhatWeDoMenuClose]}
          sx={{ fontSize: '14px', color: '#000', ...(selectedWhatWeDo === "ahead-of-ready" ? { color: '#017eff' } : {}) }}
        >
          {text.products}
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/what-we-do/domain-operations',handleWhatWeDoMenuClose]}
        sx={{  fontSize: '14px', color: '#000', ...(selectedWhatWeDo === "domain-operations" ? { color: '#017eff' } : {}) }}
        >
          {text.allDomainOperations}
        </MenuItem>
        <MenuItem
         onClick={() => [window.location.href = '/what-we-do/autonomy-ai',handleWhatWeDoMenuClose]}
        sx={{  fontSize: '14px', color: '#000', ...(selectedWhatWeDo === "autonomy-ai" ? { color: '#017eff' } : {}) }}
        >
          {text.autonomyAI}
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/what-we-do/cyber',handleWhatWeDoMenuClose]}
        sx={{  fontSize: '14px', color: '#000', ...(selectedWhatWeDo === "cyber" ? { color: '#017eff' } : {}) }}
        >
          {text.cyber}
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/what-we-do/deference-capability',handleWhatWeDoMenuClose]}
        sx={{  fontSize: '14px', color: '#000', ...(selectedWhatWeDo === "deference-capability" ? { color: '#017eff' } : {}) }}
        >
          {text.deferenceCapabilities}
        </MenuItem>
        <MenuItem
          onClick={() => [window.location.href = '/what-we-do/transformative-technology',handleWhatWeDoMenuClose]}
        sx={{  fontSize: '14px', color: '#000', ...(selectedWhatWeDo === "transformative-technology" ? { color: '#017eff' } : {}) }}
        >
          {text.transformativeTech}
        </MenuItem>
      </Menu>

       <Menu
          anchorEl={languageMenuAnchorEl}
          open={Boolean(languageMenuAnchorEl)}
          onClose={handleLanguageMenuClose}
          id={languageMenuId}
        >
          <MenuItem onClick={() => handleLanguageChange("english")}>English</MenuItem>
          <MenuItem onClick={() => handleLanguageChange("korean")}>한국어</MenuItem>
          <MenuItem onClick={() => handleLanguageChange("spanish")}>Español</MenuItem>
        </Menu>

        </Box>
    )
}

export default SubMenuComponent