import { Box, Grid, Typography } from "@mui/material"
import CapabilitiesByDomainComponent from "../component/capabilities/capabilties-by-domain.component"

const OurCapabilitiesScreen = () => {

    return (
        <Box sx={{ backgroundColor: '#f2f2f2' }}>
            <div class="container-fluid container-xxxl">
                <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row pt-5">
                                <div class="col-7 ">
                                    <div class="typedSectionHeading goType">
                                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }}>[03] Capabilities by Domain &nbsp; ___</Typography>
                                    </div>
                                </div>
                                <div class="col-5 text-right pr-md-5">
                                    View All
                                </div>
                                <div class="col-12 text-center mt-5 mb-5 ">
                                    <Typography sx={{ fontSize: 29 }}>Products trusted for our customers'<br />most important missions.</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <CapabilitiesByDomainComponent />

            </div>
        </Box>
    )
}

export default OurCapabilitiesScreen