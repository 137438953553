
const CapabilitiesByDomainScreen = () => {
   
    return (
        <p>Capabilities By Domain here</p>
    )
}

export default CapabilitiesByDomainScreen



