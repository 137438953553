import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import LinksComponent from "../component/helpful-links/link.component"
import JoinNewsLetterComponent from "../component/helpful-links/join-news-letter.component"
import { useEffect, useRef, useState } from "react";


const translations = {
    english: "HELPFUL LINKS",
    korean: "도움이 되는 링크",
    spanish: "ENLACES ÚTILES"
};


const HelpfulLinksScreen = () => {

    const [language, setLanguage] = useState("english");

    useEffect(() => {
        // Retrieve the selected language from local storage
        const storedLanguage = localStorage.getItem('language');
        
        // Set the language from local storage if it exists
        if (storedLanguage) {
          setLanguage(storedLanguage);
        }
      }, []);

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef}>
            <Box sx={{ backgroundColor: '#383677', color: '#fff', padding: 2, pl: 0, pr: 0, pb: 5 }}>
                <div class="container-fluid container-xxxl">
                    <div class="row">
                        <div class="col-12 px-md-5">
                            <div class="column-card">
                                <div class="row pt-5">
                                    <div class="col-7 text-left ">


                                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16, fontFamily: 'DIN W01 Regular' }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}>{translations[language]} &nbsp; ___</Typography>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Grid container spacing={1} sx={{ textAlign: 'start', mt: 3 }}>
                        <Grid item xs={12} sm={7}>
                            <LinksComponent language={language} />
                        </Grid>
                        <Grid item xs={12} sm={1} className="d-none d-lg-block">
                            <div style={{ borderLeft: "#ccc 1px solid", height: "100%" }}></div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <JoinNewsLetterComponent language={language} />
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </div>
    )
}

export default HelpfulLinksScreen