import { Box, Button } from "@mui/material"
import LandingComponent from "../component/landing/landing.component"
import LandingContentComponent from "../component/landing/landing-content.component"

const LandingScreen = () => {

    return (

        <Box sx={{ mt: 2, mb: 2 }}>
            
            <LandingContentComponent />

        </Box>

    )
}

export default LandingScreen
