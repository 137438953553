import { Box } from "@mui/material"
import LandingComponent from "./landing.component"
import OurPromiseLandingComponent from "./our-promise-landing.component"
import WhatWeDoLandingComponent from "./what-we-do-landing.component"
import MeetOurPeopleLandingComponent from "./meet-our-people-landing.component"
import VideoShortsLandingComponent from "./video-shorts-landing.component"
import LatestNewsLandingComponent from "./latest-news-landing.component"
import CapabilitiesByDomainLandingComponent from "./capabilities-by-domain-landing.component"

const LandingContentComponent = () => {

    return(

        <Box>
            <LandingComponent />
            <Box sx={{mb: 10}} />
            <WhatWeDoLandingComponent />
            <Box sx={{mb: 10}} />
            <OurPromiseLandingComponent />
            <Box sx={{mb: 10}} />
            <CapabilitiesByDomainLandingComponent />
            <Box sx={{mb: 10}} />
            <MeetOurPeopleLandingComponent />
            <Box sx={{mb: 10}} />
            <LatestNewsLandingComponent />
            <Box sx={{mb: 10}} />
            <VideoShortsLandingComponent />
        </Box>

    )
}

export default LandingContentComponent