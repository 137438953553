import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const CapabilitiesByDomainComponent = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia('(max-width: 768px)').matches);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleImageClick = () => {
        window.location.href = '/capabilities-by-domain-onclick';
    }

    return (
        <div className="row">
            <div className="col-12 d-flex flex-column flex-md-row pl-0 pr-0 pb-0 pb-md-5">
                {isMobile ? (
                    <Swiper
                        modules={[Navigation, Scrollbar]}
                        style={{ width: '90%', height: '180px' }}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide>
                            <div className="capabilitiesTile" onClick={handleImageClick}>
                                <div className="capabilitiesText"><h2>Title</h2></div>
                                <div className="capabilitiesArrow d-none d-md-block"></div>
                                <div className="capabilitiesImg" style={{ backgroundImage: "url('https://i.pinimg.com/originals/84/b1/5d/84b15dbb1ccc919c2640718958b25427.jpg')" }}></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="capabilitiesTile" onClick={handleImageClick}>
                                <div className="capabilitiesText"><h2>Title</h2></div>
                                <div className="capabilitiesArrow d-none d-md-block"></div>
                                <div className="capabilitiesImg" style={{ backgroundImage: "url('https://th.bing.com/th/id/OIP.EW9m6dWNz0BUszRcQGc33wHaNK?rs=1&pid=ImgDetMain')" }}></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="capabilitiesTile" onClick={handleImageClick}>
                                <div className="capabilitiesText"><h2>Title</h2></div>
                                <div className="capabilitiesArrow d-none d-md-block"></div>
                                <div className="capabilitiesImg" style={{ backgroundImage: "url('https://th.bing.com/th/id/OIP.L9M-aoIo5Myqt28H2ZwSyQHaNK?rs=1&pid=ImgDetMain')" }}></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="capabilitiesTile" onClick={handleImageClick}>
                                <div className="capabilitiesText"><h2>Title</h2></div>
                                <div className="capabilitiesArrow d-none d-md-block"></div>
                                <div className="capabilitiesImg" style={{ backgroundImage: "url('https://i.pinimg.com/originals/74/f3/04/74f3041ee34bf6957d98b16c67f03f6e.jpg')" }}></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="capabilitiesTile" onClick={handleImageClick}>
                                <div className="capabilitiesText"><h2>Title</h2></div>
                                <div className="capabilitiesArrow d-none d-md-block"></div>
                                <div className="capabilitiesImg" style={{ backgroundImage: "url('https://i.pinimg.com/originals/84/b1/5d/84b15dbb1ccc919c2640718958b25427.jpg')" }}></div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                ) : (
                    <>
                        <div className="capabilitiesTile" onClick={handleImageClick}>
                            <div className="capabilitiesText"><h2>Title</h2></div>
                            <div className="capabilitiesArrow d-none d-md-block"></div>
                            <div className="capabilitiesImg" style={{ backgroundImage: "url('https://i.pinimg.com/originals/84/b1/5d/84b15dbb1ccc919c2640718958b25427.jpg')" }}></div>
                        </div>
                        <div className="capabilitiesTile" onClick={handleImageClick}>
                            <div className="capabilitiesText"><h2>Title</h2></div>
                            <div className="capabilitiesArrow d-none d-md-block"></div>
                            <div className="capabilitiesImg" style={{ backgroundImage: "url('https://th.bing.com/th/id/OIP.EW9m6dWNz0BUszRcQGc33wHaNK?rs=1&pid=ImgDetMain')" }}></div>
                        </div>
                        <div className="capabilitiesTile" onClick={handleImageClick}>
                            <div className="capabilitiesText"><h2>Title</h2></div>
                            <div className="capabilitiesArrow d-none d-md-block"></div>
                            <div className="capabilitiesImg" style={{ backgroundImage: "url('https://th.bing.com/th/id/OIP.L9M-aoIo5Myqt28H2ZwSyQHaNK?rs=1&pid=ImgDetMain')" }}></div>
                        </div>
                        <div className="capabilitiesTile" onClick={handleImageClick}>
                            <div className="capabilitiesText"><h2>Title</h2></div>
                            <div className="capabilitiesArrow d-none d-md-block"></div>
                            <div className="capabilitiesImg" style={{ backgroundImage: "url('https://i.pinimg.com/originals/74/f3/04/74f3041ee34bf6957d98b16c67f03f6e.jpg')" }}></div>
                        </div>
                        <div className="capabilitiesTile" onClick={handleImageClick}>
                            <div className="capabilitiesText"><h2>Title</h2></div>
                            <div className="capabilitiesArrow d-none d-md-block"></div>
                            <div className="capabilitiesImg" style={{ backgroundImage: "url('https://i.pinimg.com/originals/84/b1/5d/84b15dbb1ccc919c2640718958b25427.jpg')" }}></div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CapabilitiesByDomainComponent;