import { Box, Grid, Typography } from "@mui/material"
import ReelsComponent from "../video-shorts/reels.component"
import { useEffect, useRef, useState } from "react";


const VideoShortsLandingComponent = () => {

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);


    return (
        <div ref={sectionRef}>
            <Box class="container-fluid container-xxxl">
                <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}>VIDEO SHORTS &nbsp; ___</Typography>
                                </div>
                                <div className="col-5 text-right">
                                    <a href="https://www.youtube.com/shorts/ONdFzo2tK7I" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        View All Shorts
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Box sx={{pl: 4, pr: 4}}>
                    <ReelsComponent />
                </Box>
            </Box>

        </div>
    )
}

export default VideoShortsLandingComponent