import { Box, Typography, Link } from "@mui/material";
import LatestNewsComponent from "../lates-news/latest-news.components";
import { useEffect, useRef, useState } from "react";

const LatestNewsLandingComponent = () => {
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef}>
            <Box sx={{ backgroundColor: '#f2f2f2' }}>
                <Box className="container-fluid container-xxxl">
                    <div className="row">
                        <div className="col-12 px-md-5">
                            <div className="column-card">
                                <div className="row pt-5">
                                    <div className="col-7 text-left ">
                                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'} >[05] LATEST NEWS &nbsp; ___</Typography>
                                    </div>
                                    <div className="col-5 text-right">
                                        <Link href="/latest-news" color="inherit" variant="body2">
                                            View All
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LatestNewsComponent />
                </Box>
            </Box>
        </div>
    );
}

export default LatestNewsLandingComponent;
