
import GameChangerScreen from "../screen/BannerScreen";
import LandingScreen from "../screen/LandingScreen";
import AutonomyAiScreen from "../screen/WhatWeDo/AutonomyAiScreen";
import CyberScreen from "../screen/WhatWeDo/CyberScreen";
import DeferenceCapabilityScreen from "../screen/WhatWeDo/DeferenceCapabilityScreen";
import DomainOperaionScreen from "../screen/WhatWeDo/DomainOperationScreen";
import TransformativeTechnologyScreen from "../screen/WhatWeDo/TransformativeTechnologyScreen";
import WhatWeDoScreen from "../screen/WhatWeDo/WhatWeDoScreen";
import AboutUsScreen from "../screen/WhoWeAre/AboutUsScreen";
import EconomicAndWorkforceImpactScreen from "../screen/WhoWeAre/EconomicAndWorkforceImpactScreen";
import EthicsScreen from "../screen/WhoWeAre/EthicsScreen";
import LeadershipAndGovernanceScreen from "../screen/WhoWeAre/LeadershipAndGovernanceScreen";
import OurBusinessScreen from "../screen/WhoWeAre/OurBusinessScreen";
import SocialImpactScreen from "../screen/WhoWeAre/SocialImpactScreen";
import SustainabilityScreen from "../screen/WhoWeAre/SustainabilityScreen";
import CareerScreen from "../screen/CareerScreen";
import SupplierScreen from "../screen/SuppliersScreen";
import InvestorsScreen from "../screen/InvestorsScreen";
import MeetOurPeopleScreen from "../screen/MeetOurPeopleScreen";
import PeopleStoriesComponent from "../component/meet-our-people/people-stories.component";
import NewsScreen from "../screen/NewsScreen";
import OurCapabilitiesScreen from "../screen/OurCapabilitiesScreen";
import OurProductsScreen from "../screen/OurProductsScreen";
import CookiesScreen from "../screen/CookiesScreen";
import DisclaimerScreen from "../screen/DisclaimerScreen";
import PrivacyScreen from "../screen/PrivacyScreen";
import DataProtectionScreen from "../screen/DataProtectionScreen";
import CommunityScreen from "../screen/CommunityScreen";
import LeadershipScreen from "../screen/LeadershipScreen";
import NewsroomScreen from "../screen/NewsroomScreen";
import EmployeesScreen from "../screen/EmployeesScreen";
import InternationalScreen from "../screen/InternationalScreen";
import ContactUsScreen from "../screen/ContactUsScreen";
import MediaRelationsScreen from "../screen/MedialRelationsScreen";
import MultimediaScreen from "../screen/MultimediaScreen";
import DisclosuresScreen from "../screen/DisclosuresScreen";
import CapabilitiesByDomainScreen from "../screen/CapabilitiesByDomainScreen";
import CapabilitiesByDomainOnClickScreen from "../screen/CapabilitiesByDomainOnClickScreen";
import LatestNewsScreen from "../screen/LatestNewsScreen";


export const routes =[
    {
        path: "/",
        name: "LandingScreen",
        element: <LandingScreen/>,
        isPrivate: false
    },
    {
        path: "/learn-more/game-changer",
        name: "GameChanger",
        element: <GameChangerScreen />,
        isPrivate: false
    },
    {
        path: "/learn-more/ahead-of-ready",
        name: "WhatWeDoScreen",
        element: <WhatWeDoScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are",
        name: "AboutUsScreen",
        element: <AboutUsScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are/leadership-governance",
        name: "LeadershipAndGovernanceScreen",
        element: <LeadershipAndGovernanceScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are/our-business",
        name: "OurBusinessScreen",
        element: <OurBusinessScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are/social-impact",
        name: "SocialImpactScreen",
        element: <SocialImpactScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are/ethics",
        name: "EthicsScreen",
        element: <EthicsScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are/sustainability",
        name: "SustainabilityScreen",
        element: <SustainabilityScreen />,
        isPrivate: false
    },
    {
        path: "/who-we-are/economic-and-workforce-impact",
        name: "EconomicAndWorkforceImpactScreen",
        element: <EconomicAndWorkforceImpactScreen />,
        isPrivate: false
    },
    {
        path: "what-we-do/domain-operations",
        name: "DomainOperaionScreen",
        element:<DomainOperaionScreen />,
        isPrivate: false
    }, 
    {
        path: "what-we-do/autonomy-ai",
        name: "AutonomyAiScreen",
        element: <AutonomyAiScreen />,
        isPrivate: false
    }, 
    {
        path: "what-we-do/cyber",
        name: "CyberScreen",
        element: <CyberScreen />,
        isPrivate: false
    }, 
    {
        path: "what-we-do/deference-capability",
        name: "DeferenceCapabilityScreen",
        element:<DeferenceCapabilityScreen />,
        isPrivate: false
    }, 
    {
        path: "what-we-do/transformative-technology",
        name: "TransformativeTechnologyScreen",
        element:<TransformativeTechnologyScreen />,
        isPrivate: false
    }, 
    {
        path: "/career",
        name: "CareerScreen",
        element: <CareerScreen />,
        isPrivate: false
    },
    {
        path: "/contact/disclaimer/privacy",
        name: "PrivacyScreen",
        element:<PrivacyScreen />,
        isPrivate: false
    },
    {
        path: "/contact/disclaimer",
        name: "DisclaimerScreen",
        element: <DisclaimerScreen/> ,
        isPrivate: false
    },
    {
        path: "/contact/radix-group-incorporated-privacy-notice",
        name: "DataProtectionScreen",
        element: <DataProtectionScreen /> ,
        isPrivate: false
    },
    {
        path: "/contact/cookies",
        name: "CookiesScreen",
        element:<CookiesScreen />,
        isPrivate: false
    },
    {
        path: "/community",
        name: "CommunityScreen",
        element: <CommunityScreen />,
        isPrivate: false
    },
    {
        path: "/contact-us",
        name: "ContactUsScreen",
        element: <ContactUsScreen />,
        isPrivate: false
    },
    {
        path: "/disclosures",
        name: "DisclosuresScreen",
        element: <DisclosuresScreen />,
        isPrivate: false
    },
    {
        path: "/employees",
        name: "EmployeesScreen",
        element: <EmployeesScreen />,
        isPrivate: false
    }, 
    {
        path: "/helpful-links",
        name: "HelpfulLinksScreen",
        // element:
        isPrivate: false
    }, 
    {
        path: "/international",
        name: "InternationalScreen",
        element: <InternationalScreen />,
        isPrivate: false
    }, 
    {
        path: "/investors",
        name: "InvestorsPage",
        element: <InvestorsScreen />,
        isPrivate: false
    }, 
    {
        path: "/news",
        name: "NewsScreen",
        element: <NewsScreen />,
        isPrivate: false
    }, 
    {
        path: "/latest-news",
        name: "LatestNewsScreen",
        element: <LatestNewsScreen />,
        isPrivate: false
    }, 
    {
        path: "/leadership",
        name: "LeadershipScreen",
        element: <LeadershipScreen />,
        isPrivate: false
    }, 
    {
        path: "/medial-relations",
        name: "MedialRelationsScreen",
        element: <MediaRelationsScreen />,
        isPrivate: false
    }, 
    {
        path: "/meet-our-people/careers",
        name: "MeetOurPeopleScreen",
        element: <MeetOurPeopleScreen />,
        isPrivate: false
    }, 
    {
        path: "/meet-our-people/career/life-at",
        name: "PeopleStoriesComponent",
        element: <PeopleStoriesComponent />,
        isPrivate: false
    }, 
    {
        path: "/multimedia",
        name: "MultimediaScreen",
        element: <MultimediaScreen />,
        isPrivate: false
    }, 
    {
        path: "/newsroom",
        name: "NewsroomScreen",
        element: <NewsroomScreen />,
        isPrivate: false
    }, 
    {
        path: "/our-capabilities",
        name: "OurCapabilitiesScreen",
        element: <OurCapabilitiesScreen />,
        isPrivate: false
    }, 
    {
        path: "/capabilities-by-domain",
        name: "CapabilitiesByDomainScreen",
        element: <CapabilitiesByDomainScreen />,
        isPrivate: false
    }, 
    {
        path: "/our-products",
        name: "OurProductsScreen",
        element: <OurProductsScreen />,
        isPrivate: false
    }, 
    {
        path: "/capabilities-by-domain-onclick",
        name: "CapabilitiesByDomainOnClickScreen",
        element: <CapabilitiesByDomainOnClickScreen />,
        isPrivate: false
    }, 
    {
        path: "/sign-up",
        name: "InternationalScreen",
        // element:
        isPrivate: false
    }, 
    {
        path: "/suppliers",
        name: "SuppliersScreen",
        element:<SupplierScreen />,
        isPrivate: false
    }, 
    
]
