import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../../common/custom-tabs.component";

function useTabs(props) {
 const [game_changer_tab, setGameChangerTab] = useState(null);

 function setupTabs() {
    var tabs_default = [
      { name: "Item One", component: <iframe src="https://www.youtube.com/embed/krDWc30PAGg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> },
      { name: 'Item Two', component: <iframe src="https://www.youtube.com/embed/fCVm5U2ob8U" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> },
      { name: 'Item Three', component: <iframe src="https://www.youtube.com/embed/mkggXE5e2yk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> },
    ];

    setGameChangerTab(tabs_default);
 }

 useEffect(() => { setupTabs(); }, []);

 return game_changer_tab;
}

function GameChangerTabComponent(props) {
 const tabs = useTabs(props);
 const [value, setValue] = useState(null); 
 const [videoVisible, setVideoVisible] = useState(false); // New state to track video visibility

 const handleChange = (event, newValue) => {
  setValue(newValue);
  if (value === newValue) {
    setVideoVisible(!videoVisible); // Toggle video visibility
  } else {
    setVideoVisible(true); // Show video when a different tab is clicked
  }
};

 const handleTabClick = (index) => {
    setValue(index);
    setVideoVisible(!videoVisible); // Toggle video visibility
 };

 function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
 }

 function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
 }

 return (
    <Box>
      <CustomTabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{ marginTop: 2 }}
        indicatorColor="transparent"
      >
        {tabs?.map((tab, index) => (
          <CustomTab 
            label={tab.name} 
            disableRipple 
            {...a11yProps(index)} 
            onClick={() => handleTabClick(index)} // Pass the onClick handler
          />
        ))}
      </CustomTabs>

      {tabs?.map((tab, index) => (
        <Box key={index} sx={{ width: "98%", margin: "auto" }}>
          <TabPanel value={value} index={index} style={{ padding: "0px" }}>
            {videoVisible && ( // Use videoVisible state to conditionally render the video
              <div style={{ position: 'relative', paddingTop: '56.25%', maxWidth: '1500px', margin: 'auto' }}>
                <iframe
                 style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '80%' }}
                 src={tab.component.props.src}
                 frameBorder="0"
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                 allowFullScreen
                ></iframe>
              </div>
            )}
          </TabPanel>
        </Box>
      ))}
    </Box>
 );
}

export default GameChangerTabComponent;
