import { Box, Button, Typography } from "@mui/material"

const translations = {
    english: {
        title: "Join the Radix newsletter",
        description: "Get articles on the innovative projects Radix developers and supports are working on right now.",
        signUp: "SIGN UP NOW"
    },
    korean: {
        title: "Radix 뉴스레터에 가입하세요",
        description: "Radix 개발자 및 지원자들이 현재 작업하고 있는 혁신적인 프로젝트에 대한 기사를 받아보세요.",
        signUp: "지금 가입하기"
    },
    spanish: {
        title: "Únete al boletín de noticias de Radix",
        description: "Obtén artículos sobre los proyectos innovadores en los que los desarrolladores y colaboradores de Radix están trabajando en este momento.",
        signUp: "REGÍSTRATE AHORA"
    }
};

const JoinNewsLetterComponent = (props) => {

    const { language } = props;
   
    return (
        <Box class="col-9 col-lg-3 pl-md-5 pl-lg-0 pr-md-5" style={{ marginTop: 10 }}>
            <Typography class="reverseWhite mb-4" style={{ fontSize: 30, mb: 6 }}>{translations[language].title}</Typography>
            <Typography class="mb-4" sx={{ fontSize: 16, mb: 2 }}>{translations[language].description}</Typography>
            <Button class="actionButton mt-md-4" >{translations[language].signUp}</Button>
        </Box>
    )
}

export default JoinNewsLetterComponent