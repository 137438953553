import { Box, Button, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const OurPromiseLandingComponent = () => {

    const navigate = useNavigate();

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef}>
        <Box sx={{ textAlign: 'start' }}>
            <div class="container-fluid container-xxxl">
                <div class="bannerOverlayArea pb-3 pb-md-0">

                    <div class="bannerOverlayMinimizeImage bannerFull"
                        style={{
                            backgroundImage: `url("https://assets.coursehero.com/study-guides/lumen/images/atd-fscj-introastronomy/basic-objects-in-the-universe/figure-8-universe8.jpg")`,
                            backgroundPosition: '50% 50%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                    </div>

                    <div class="bannerOverlayTop col-12 pl-md-5">
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'} >[02] Our Promise &nbsp; ___</Typography>
                    </div>

                    <div class="bannerOverlayText col-12  pt-2 pb-3 pb-md-5 pl-md-5" >
                        <div class="bannerTextShortTitle"></div>

                        <div class="bannerTextSubTitle d-none d-md-block">
                            <Typography sx={{ textAlign: 'start', fontSize: 24, fontWeight: 'regular', mb: 5 }}>It's our identity, our culture, and our promise.</Typography>
                        </div>
                        <Button class="actionButton mt-md-4" onClick={() => navigate('/learn-more/ahead-of-ready')} >Learn More</Button>
                    </div>

                </div>
            </div>
        </Box>
        </div>
    )
}

export default OurPromiseLandingComponent