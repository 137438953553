import { Box, Button, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";


const MeetOurPeopleScreen = () => {

    return (
        <p>Meet Our People here</p>
    )
}

export default MeetOurPeopleScreen